import { Controller } from "@hotwired/stimulus"

function getLastInnermostDiv(someElement) {
  const results = someElement.querySelectorAll("div")

  if (results.length === 0) {
    return someElement
  }

  const lastElementIdx = results.length - 1
  return results[lastElementIdx]
}

export default class extends Controller {
  static classes = [ "hidden", "loading" ]
  static targets = [ "beforeFoldContent", "unfolder", "foldedContent", "folder" ]

  static values = {
    foldLabel: String,
    unfoldLabel: String
  }

  connect() {
    this.#shiftTogglers()
  }

  unfold() {
    this.foldedContentTarget.classList.remove(this.hiddenClass)
    this.unfolderTarget.classList.add(this.hiddenClass)
  }

  fold() {
    this.foldedContentTarget.classList.add(this.hiddenClass)
    this.unfolderTarget.classList.remove(this.hiddenClass)
  }

  // Private
  #shiftTogglers() {
    this.#shiftTogglerIntoContainer(
      this.unfolderTarget,
      this.beforeFoldContentTarget
    )

    this.#shiftTogglerIntoContainer(
      this.folderTarget,
      this.foldedContentTarget
    )
  }

  #shiftTogglerIntoContainer(toggler, container) {
    const innermostDiv = getLastInnermostDiv(container)
    innermostDiv.appendChild(toggler)
    toggler.classList.remove(this.hiddenClass)
  }
}
